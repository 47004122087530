/* eslint jsx-a11y/label-has-for:0 */

import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header } from '../components'

import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`

const Uses = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`Uses | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <h1>What Sheree Uses</h1>
        <p>Stuff they use in their day to day work life.</p>
        <h2 id="toc_1">Hardware &amp; Tangibles</h2>

        <p>
          They have more computers than seems reasonable but they have their own home office and do an incredible amount
          of software testing and development.
        </p>

        <ul>
          <li>
            <p>MacBook Pro 15&quot; Catalina daily work machine</p>
          </li>
          <li>
            <p>MacBook Pro 13&quot; Mojave for testing</p>
          </li>
          <li>
            <p>MacBook Air 13&quot; High Sierra with some oooold OS for testing</p>
          </li>
          <li>
            <p>MacMini devserver, CI, etc</p>
          </li>
          <li>
            <p>
              <a href="https://fit-iot.com/web/product/mbm2-pro/">MintBox Mini Pro 2</a> - but now running{' '}
              <a href="https://www.kali.org/">Kali Linux</a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://system76.com/laptops/galago">System76 Galago Pro</a> running{' '}
              <a href="https://system76.com/pop">Pop!</a> daily{' '}
              <a href="/laptop-stickers-system-76-pop-os-box-edition">personal</a> machine
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.microsoft.com/en-us/p/surface-laptop-1st-gen/90fc23dv6snz?activetab=pivot:overviewtab">
                Surface Laptop
              </a>{' '}
              <a href="/my-first-windows-computer/">great hardware, dreadful OS</a>,{' '}
              <a href="https://docs.microsoft.com/en-us/windows/wsl/install-win10">definitely install WSL</a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.aeris.de/en/muvman/">Muvman</a> - ergonomic stoolchair
            </p>
          </li>
          <li>
            <p>
              <a href="https://mechanicalkeyboards.com/shop/index.php?l=product_detail&amp;p=1950">Pink Filco</a> with
              Blues and a <a href="https://x-bows.com/">X-bows Ergo</a> with Blues
            </p>
          </li>
          <li>
            <p>
              headphones - still hunting for something that doesn&#39;t hurt my ears while I&#39;m also wearing glasses
            </p>
          </li>
          <li>
            <p>
              <a href="/get-computer-glasses-they-are-worth-it/">Computer glasses</a> - seriously, protect your eyes!
            </p>
          </li>
          <li>
            <p>
              <a href="https://bulletjournal.com">Bullet Journal</a> - my{' '}
              <a href="/i-just-learned-about-bullet-journals/">ubiquitous capture</a> device
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.circadianoptics.com/product/lumos">Sad lamp</a> - because Seasonal Affective Disorder
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.raindesigninc.com/mstand360.html">Rain Stand</a> - laptop stand that swivels!
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.stumpstore.com">Stump Stand</a> - device stand that can take a beating
            </p>
          </li>
        </ul>

        <h2 id="toc_2">Dev Tools &amp; Coding Setup</h2>

        <p>
          Development is only part of my gig, I don&#39;t know what &quot;real&quot; devs do but it&#39;s working for
          me.
        </p>

        <ul>
          <li>
            <p>
              <a href="https://code.visualstudio.com/">VSCode</a> - I just use whatever my devs use, it&#39;s nice
              enough
            </p>
          </li>
          <li>
            <p>
              <a href="https://developer.apple.com/xcode/">Xcode</a> - am i the only person out there that prefers it?
            </p>
          </li>
          <li>
            <p>
              <a href="https://developer.android.com/studio/">Android Studio</a> - luv2 emulate crappy devices
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.docker.com/">Docker</a> - do you really need to containerize it?
            </p>
          </li>
          <li>
            <p>
              <a href="https://jenkins.io/">Jenkins</a> - i know there are a million options out there but a local
              dockerized jenkins doing my bidding is awfully nice
            </p>
          </li>
          <li>
            <p>
              <a href="https://dank.sh/">Dank Mono</a> - nice dev typeface,{' '}
              <a href="/dank-mono-font/">worth the money</a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://kapeli.com/dash">Dash</a> - docs Docs DOCS
            </p>
          </li>
          <li>
            <p>
              <a href="https://ohmyz.sh/">ZSH/Oh my ZSH</a> - pretty much only use the git aliases and shell themes
            </p>
          </li>
          <li>
            <p>
              <a href="https://github.com/minamarkham/yonce">Yonce</a> - who run the world ?
            </p>
          </li>
          <li>
            <p>
              <a href="https://vuejs.org/">Vue</a> - not to be needlessly contrarian to my React-heavy workplace but
              because fuck facebook
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.git-tower.com">Tower</a> - CLI is great but I spend a lot of time just browsing the
              commit history
            </p>
          </li>
          <li>
            <p>
              <a href="https://cloud.google.com/">Gcloud tools</a> - plz don&#39;t make me learn AWS
            </p>
          </li>
          <li>
            <p>
              <a href="https://coderunnerapp.com/">CodeRunner</a> - for local experiments because I&#39;m always up in
              someone else&#39;s stack and codebase
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.mozilla.org/en-US/firefox/channel/desktop/">FireFox Dev Tools</a> - Chrome is OK and
              all but <a href="/it-is-time-to-switch-to-firefox/">FF is my daily driver</a> for devwork
            </p>
          </li>
        </ul>

        <h2 id="toc_3">Test Tools</h2>

        <p>Testing is basically taking all the dev stuff and then adding a ton of extra fun.</p>

        <ul>
          <li>
            <p>Literally every browser they can get their hands on</p>
          </li>
          <li>
            <p>
              <a href="https://www.choosyosx.com/">Choosy</a> - is there an Ubuntu or Windows tool that does the same
              sweet jesus they need it.
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.vmware.com/products/fusion.html">VMWare</a> - mostly just Fusion, even my scads of
              computers aren&#39;t enough
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.getpostman.com/">Postman</a> - to wrangle their API
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.charlesproxy.com/">Charles Proxy</a> - luv 2 sniff ur traffic
            </p>
          </li>
          <li>
            <p>
              <a href="http://mongodb-tools.com/tool/mongohub/">Mongohub</a> - for browsing data, which I do a lot
            </p>
          </li>
          <li>
            <p>
              <a href="https://evernote.com/products/skitch">Skitch</a> - the old one, before they ruined it, still
              works, still the best
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.cypress.io/">Cypress</a> - slightly less awful than dealing with Selenium, UI tests
              are still the devil
            </p>
          </li>
          <li>
            <p>
              <a href="/accessibility-resources/">More AX tools</a> than I can keep track of
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.browserstack.com">Browserstack</a> - browser testing is less important than it used
              to be but ya still gotta do it
            </p>
          </li>
          <li>
            <p>
              <a href="https://blisk.io">Blisk.io</a> - live reloading for mobile desktop makes my web local dev much
              easier
            </p>
          </li>
          <li>
            <p>
              <a href="https://developers.google.com/web/tools/lighthouse/">Lighthouse</a> - accessibility NOW! also a
              pretty decent perf indicator
            </p>
          </li>
        </ul>

        <h2 id="toc_4">Cool Tools</h2>

        <p>All these tools crossover the dev/test barrier and help keep them sane.</p>

        <ul>
          <li>
            <p>
              <a href="https://1password.com">1Password</a> - wish the cross OS experience was better
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.alfredapp.com">Alfred</a> - mostly just for clipboard history, it can do so much
              more!
            </p>
          </li>
          <li>
            <p>
              <a href="https://textexpander.com">TextExpander</a> - why would I type all that shit out every time, my
              god
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.kaleidoscopeapp.com">Kaleidoscope</a> - I will love you to the end of time
            </p>
          </li>
          <li>
            <p>
              <a href="https://sketchapp.com">Sketch</a> - can&#39;t wait to see what you have in store
            </p>
          </li>
          <li>
            <p>
              <a href="https://mindnode.com">MindNode Pro</a> - like whiteboarding, how I brainstorm
            </p>
          </li>
          <li>
            <p>
              <a href="https://github.com/notable/notable">Notable</a> - I use this as a git based Markdown wiki
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.deckset.com">Deckset</a> - I don&#39;t give enough talks but when I do you bet your
              ass they are in Markdown
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.pixelmator.com/pro/">Pixelmator</a> - for quick img edits
            </p>
          </li>
          <li>
            <p>
              <a href="http://markdownpro.com">Markdown Pro</a> - Mou.app died and I have yet to find a suitable
              alternative
            </p>
          </li>
          <li>
            <p>
              <a href="https://ia.net/writer">IAWriter</a> - for deep thoughts so like once a year
            </p>
          </li>
          <li>
            <p>
              <a href="https://encrypt.me">Encrypt.me</a> - VPN zzz
            </p>
          </li>
          <li>
            <p>
              <a href="http://netnewswireapp.com">NetNewsWire</a> - I read the entire web every single day
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.obdev.at/products/littlesnitch/index.html">Lil Snitch</a> - i will block you so fast
              you little shit
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.shirt-pocket.com/SuperDuper/SuperDuperDescription.html">SuperDuper</a> - is our
              children backing up they data?
            </p>
          </li>
          <li>
            <p>
              <a href="https://discordapp.com">Discord</a> - gotta keep up with the cool teens
            </p>
          </li>
          <li>
            <p>
              <a href="https://slack.com">Slack</a> - only critical orgs go in the app, the rest relagated to the
              browser
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.rescuetime.com">RescueTime</a> - you would think years of billing by the hour would
              have taught me to timetrack but no
            </p>
          </li>
        </ul>

        <h2 id="toc_5">Continuous Learning</h2>

        <p>Gotta keep fresh and up to date. I use these and Twitter/RSS to keep up with things.</p>

        <ul>
          <li>
            <p>
              <a href="https://egghead.io/">Egghead.io</a> - worth it if on sale
            </p>
          </li>
          <li>
            <p>
              <a href="http://linkedin.com/learning/">LinkedIn Learning</a> - just use your library card
            </p>
          </li>
          <li>
            <p>
              <a href="https://linuxacademy.com/">LinuxAcademy</a> - they have servers you can use, it&#39;s totally
              worth it
            </p>
          </li>
          <li>
            <p>
              <a href="https://wesbos.com/blog/">WesBos</a> - for all my web noodling
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.leveluptutorials.com/">LevelUp Tuts</a> - excellent free and not free content
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.udemy.com/">Udemy</a> - 90% off forever
            </p>
          </li>
          <li>
            <p>
              <a href="https://www.eviltester.com/page/onlinetraining/techwebtesting101/">EvilTester</a> - some good
              stuff here
            </p>
          </li>
          <li>
            <p>
              <a href="https://frontendmasters.com/courses">FrontEndMasters</a> - great content by everyone you follow
              on twitter
            </p>
          </li>
          <li>
            <p>
              <a href="https://developer.mozilla.org/en-US/">MDN</a> - bless Mozilla
            </p>
          </li>
        </ul>
      </Content>
    </Wrapper>
  </Layout>
)

export default Uses
